<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Tên phòng họp" v-model="meetingRoomInfo.name"
                                  v-validate="'required'" name="name"/>
                        <span class="text-danger text-sm">{{ errors.first('name') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Địa điểm" v-model="meetingRoomInfo.location"
                                  v-validate="'required'" name="location"/>
                        <span class="text-danger text-sm">{{ errors.first('location') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Số người" v-model="meetingRoomInfo.capacity"
                                  name="capacity" type="number"/>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col lg:w-1/3 w-full mt-2">
                        <vs-checkbox v-model="meetingRoomInfo.status" class="mb-1">Trạng thái hoạt động</vs-checkbox>
                    </div>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="listMeetingRoom">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            meetingRoomInfo: {},
        }
    },
    created() {


        this.meetingRoomId = this.$route.query.id;

        if (!this.meetingRoomId)
            return this.meetingRoomInfo = {
                name: '',
                status: 1,
                location: '',
                capacity: ''
            };

        this.$vs.loading();
        this.$crm.get(`/meeting-room/${this.meetingRoomId}`)
            .then((response) => {
                this.$vs.loading.close();
                if (response.data) {
                    this.isUpdate = true;
                    this.meetingRoomInfo = response.data;
                }
            })
            .catch(() => {
                this.$vs.loading.close();
                this.meetingRoomInfo = {}
            });
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                if (this.isUpdate) {

                    let url = `/meeting-room/update`,
                        data = this.meetingRoomInfo;
                    data.status = data.status ? 1 : 0;
                    this.$vs.loading();
                    this.$crm.put(url, data).then(() => {
                        this.$vs.notify({
                            color: 'success',
                            text: `Cập nhật phòng họp thành công`,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                        this.$vs.loading.close();
                        this.$router.push(`/user/manage-meeting-room${this.meetingRoomInfo.organizationBranchId ? `?branch_id=${this.meetingRoomInfo.organizationBranchId}` : ''}`).catch(() => {
                        })
                    }).catch((err) => {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                } else {
                    let url = `/meeting-room/create`,
                        data = this.meetingRoomInfo;
                    data.status = data.status ? 1 : 0;
                    this.$vs.loading();
                    this.$crm.post(url, data).then(() => {
                        this.$vs.notify({
                            color: 'success',
                            text: `Thêm phòng họp thành công`,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                        this.$vs.loading.close();
                        this.$router.push(`/user/manage-meeting-room${this.meetingRoomInfo.organizationBranchId ? `?branch_id=${this.meetingRoomInfo.organizationBranchId}` : ''}`).catch(() => {
                        })
                    }).catch((err) => {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                }
            } catch (ex) {
                console.log(ex);
            }
        },
        listMeetingRoom() {
            this.$router.push(`/user/manage-meeting-room${this.meetingRoomInfo.organizationBranchId ? `?branch_id=${this.meetingRoomInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
